.background-icon {
    position: absolute;
    font-size: 400px;
    z-index: -1;
    opacity: 0.1;
}

@media screen and (max-width: 1200px) {
    .background-icon {
        font-size: 300px !important;
    }
}

@media screen and (max-width: 950px) {
    .background-icon {
        font-size: 200px !important;
    }
}

@media screen and (max-width: 650px) {
    .background-icon {
        display: none;
    }
}